import { RefObject, useCallback, useEffect, useState } from 'react';

import { $config, $initialState } from '@client/core/atoms/config';
import { updateMetricByKey } from '@client/core/atoms/metrics';
import { updatePlacementKeyValueById } from '@client/core/atoms/placements';
import { getAllFeatureStatuses } from '@client/core/atoms/unleashFeatures';
import { useInScreen } from '@client/core/hooks';
import { getInViewSettingsByBrand } from '@client/core/utils/getInViewSettingsByPlacementId';
import {
  ClientAdPlacement,
  debugLog,
  GamConfig,
  GamPlacement,
  getSizesByMediaType,
  PlacementId,
  PlacementStatusesEnum,
  Slot
} from '@schibsted-nmp/advertising-shared';

import { getDebouncedLoadAdFunction } from '../batch';
import { setTargetingOnSlotOrGlobal } from '../targeting';
import { applySizeMapping } from '../utils/sizeMapping';
import { setupGamEventListeners } from './setupGamEventListeners';
import { ensurePathStartsWithSlash } from './utils';

type Props = {
  containerId: string;
  placement: ClientAdPlacement<GamPlacement>;
  ref: RefObject<HTMLDivElement>;
  forecasting?: boolean;
};

export function useInitiateGamUnit(props: Props) {
  const [placementIsInView, setPlacementIsInView] = useState(false);

  const { adServer } = $config.get();
  const gamConfig = adServer.gam as GamConfig;
  const { placement } = props;
  const { placementId, status } = placement;

  const { inViewSettings: hasInViewSettings, isIntersecting: isInView } =
    useShouldLoadAd({
      placementId,
      ref: props.ref
    });
  const [slotState, setSlotState] = useState<Slot | null>(null);

  /**
   * This loads the ads in a batch
   */
  const sendToRequestBatchAds = useCallback((slot: Slot | null) => {
    if (slot) {
      getDebouncedLoadAdFunction()(slot);
    }
  }, []);

  // Some placements need to wait for a delay before they are in view, because
  // of pagination trouble etc
  useEffect(() => {
    if (status === 'refresh') {
      // Wait for ads that are currently in view
      if (!isInView || !hasInViewSettings) {
        updatePlacementKeyValueById(placementId, 'status', 'pending');
        setPlacementIsInView(isInView);
      }
    } else {
      setPlacementIsInView(isInView);
    }
  }, [isInView, status, placementId, hasInViewSettings]);

  useEffect(() => {
    if (slotState && placementIsInView && status === 'pending') {
      updatePlacementKeyValueById(placementId, 'status', 'request');
      sendToRequestBatchAds(slotState);
    }
  }, [slotState, placementIsInView, placementId, status]);

  const getSlot = (placement: ClientAdPlacement<GamPlacement>) => {
    const {
      targeting = [],
      path,
      sizeMappings,
      mediaTypes
    } = placement.adServer?.config || {};

    const sizes = getSizesByMediaType(mediaTypes) as number[][];

    const placementConfigPath = ensurePathStartsWithSlash(path);

    const slot = window.googletag
      .defineSlot(placementConfigPath, sizes, placement.placementId)
      .addService(window.googletag.pubads());

    // Apply size mapping if available
    if (sizeMappings && sizeMappings.length > 0) {
      applySizeMapping(slot, sizeMappings, window.googletag);
    }

    if ($initialState.get().env === 'local') {
      targeting.push({ key: 'test', value: 'true' });
    }

    const { enableGamForecasting, enableGamTestCampaign } =
      getAllFeatureStatuses();

    if (enableGamForecasting && props.forecasting) {
      targeting.push({ key: 'forecasting', value: 'true' });
    }
    if (enableGamTestCampaign) {
      targeting.push({ key: 'gamTestCampaign', value: 'true' });
    }

    if (targeting?.length > 0) {
      setTargetingOnSlotOrGlobal({
        slot,
        targeting,
        global: false
      });
    }
    return slot;
  };
  /**
   * Set up the ad
   */
  useEffect(() => {
    window.googletag = window.googletag || {};
    window.googletag.cmd = window.googletag.cmd || [];

    if (placementId && !slotState) {
      // TODO: Implement consent handling with getConsentStatusOrSubscribe, same as with Xandr?
      window.googletag.cmd.push(() => {
        // Check if the slot is already defined and destroy it if necessary
        if (
          window.googletag
            .pubads()
            .getSlots()
            .some((slot: any) => slot.getSlotElementId() === placementId)
        ) {
          // slot already exists
          return;
        }
        debugLog('Defining Slot for placement: ', placementId);

        const slot = getSlot(placement);

        if (slot) {
          setSlotState(slot);
          updateMetricByKey(placementId, PlacementStatusesEnum.SlotCreated);
          debugLog('Gam successfully set-up for placement:', placementId);

          // Add event listeners for ad events
          setupGamEventListeners(slot);
        }
      });
    }
  }, [placement, gamConfig, placementId, getSlot, slotState]);

  return { placement };
}

function useShouldLoadAd({
  placementId,
  ref
}: {
  placementId: PlacementId;
  ref: RefObject<HTMLDivElement>;
}) {
  const inViewSettings = getInViewSettingsByBrand(placementId);

  const { isIntersecting } = useInScreen({
    ref,
    ...(inViewSettings || {})
  });

  return {
    inViewSettings,
    isIntersecting: isIntersecting || !inViewSettings
  };
}
