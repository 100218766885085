import React, { useState } from 'react';

import classNames from 'classnames';

import { useIsSmallScreen } from '@client/dev/components/utils/useIsSmallScreen';

export const DevPanelContainer = ({ isOpen, children }) => {
  const isSmallScreen = useIsSmallScreen();
  const [animate, setAnimate] = useState(true);

  const handleMouseDown = () => {
    setAnimate(false); // Disable animation on resize
  };
  const onMouseUp = () => {
    setAnimate(true); // Disable animation on resize
  };

  return (
    <div
      className={classNames(
        'shadow-s s-bg fixed top-0 left-0 flex flex-col p-2 gap-8 z-30 overflow-auto max-h-screen min-h-28 min-w-96 p-0',
        {
          'transition-all duration-500 ease-in-out': animate,
          'max-w-screen-9/10': isSmallScreen,
          'max-w-screen': !isSmallScreen,
          'overflow-y-scroll overflow-y-auto': isOpen,
          'overflow-hidden': !isOpen
        }
      )}
      style={{
        maxHeight: '100dvh',
        resize: isOpen ? 'both' : 'none',
        width: isOpen ? (isSmallScreen ? '80%' : '425px') : 90,
        height: isOpen ? (isSmallScreen ? '100%' : '800px') : 30,
        overscrollBehavior: 'contain'
      }}
      onMouseDown={handleMouseDown}
      onMouseUp={onMouseUp}
    >
      {children}
    </div>
  );
};
