import React, { useState, useEffect } from 'react';

import { $config } from '@client/core/atoms/config';

type Placement = {
  placementId: string;
  gamPath: string;
  [key: string]: any;
};

function ObjectExplorer({
  data,
  depth = 0,
  keyName = '',
  inPlacement = false
}) {
  const keysToClose = [
    'placementTypesById',
    'bannerAdsIds',
    'instreamAdsIds',
    'userSync',
    'allAdsIds'
  ];
  const [isOpen, setIsOpen] = useState(
    !keysToClose.includes(keyName) || inPlacement
  );

  if (typeof data !== 'object' || data === null) {
    return <span>{JSON.stringify(data)}</span>;
  }

  const isArray = Array.isArray(data);
  const isEmpty = Object.keys(data).length === 0;

  return (
    <div className="text-s" style={{ marginLeft: `${depth * 4}px` }}>
      <span onClick={() => setIsOpen(!isOpen)} style={{ cursor: 'pointer' }}>
        {isOpen ? '▼' : '▶'}
        {isArray ? '[' : '{'}
        {isEmpty && (isArray ? ']' : '}')}
      </span>

      {isOpen && !isEmpty && (
        <>
          <div>
            {Object.entries(data).map(([key, value]) => (
              <div key={key}>
                <span>{key}: </span>
                <ObjectExplorer
                  data={value}
                  depth={depth + 1}
                  keyName={key}
                  inPlacement={inPlacement || keyName === 'placements'}
                />
              </div>
            ))}
          </div>
          <div style={{ marginLeft: `${depth * 4}px` }}>
            {isArray ? ']' : '}'}
          </div>
        </>
      )}
    </div>
  );
}

const RelevantTab = () => {
  const [filteredPlacements, setFilteredPlacements] = useState<Placement[]>([]);
  const [generalConfigs, setGeneralConfigs] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (typeof window.relevantDigital === 'undefined') {
      setError('RelevantDigital is not defined.');
      return;
    }

    const deviceTypeMapping: Record<string, string> = {
      desktop: 'wde',
      mobile: 'wph'
    };

    const config = $config.get();
    const deviceType = deviceTypeMapping[config?.deviceType] || 'unknownDevice';
    const pageType = config?.pageType || 'unknownPage';

    async function fetchConfigs() {
      try {
        const response = await window.relevantDigital.getConfigs();

        const pageConfig = response.find((config: any) => config?.configId);

        if (!pageConfig || !pageConfig.adUnits) {
          setError('No placements found for the current page.');
          return;
        }

        const { adUnits, ...generalConfig } = pageConfig;
        setGeneralConfigs(generalConfig);

        const filtered = (adUnits as Placement[]).filter((unit) => {
          const gamPath = unit.gamPath || '';
          return gamPath.includes(deviceType) && gamPath.includes(pageType);
        });

        if (filtered.length === 0) {
          setError('No matching placements found.');
          return;
        }

        setFilteredPlacements(filtered);
      } catch {
        setError('Failed to fetch configurations.');
      }
    }

    fetchConfigs();
  }, []);

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div>
      {generalConfigs && (
        <div>
          <h4>General Configurations:</h4>
          <ObjectExplorer data={generalConfigs} />
        </div>
      )}

      <div>
        <h4>Placements:</h4>
        {filteredPlacements.length > 0 ? (
          filteredPlacements.map((placement, index) => (
            <div key={index}>
              <h5>
                Placement ID: {placement.placementId} | GAM Path:{' '}
                {placement.gamPath}
              </h5>
              <ObjectExplorer data={placement} />
            </div>
          ))
        ) : (
          <p>Loading relevant placements...</p>
        )}
      </div>
    </div>
  );
};

export default RelevantTab;
