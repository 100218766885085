import { useEffect } from 'react';

import { getInViewSettingsByBrand } from '@client/core/utils/getInViewSettingsByPlacementId';
import { pushToAnqSafe, showTag } from '@client/xandr/xandr';
import {
  AdObj,
  NativeAdObj,
  PlacementId
} from '@schibsted-nmp/advertising-shared';

import { useInScreen } from './useInScreen';

export function useLoadNativeAd(
  adObj: NativeAdObj,
  targetId: string,
  placementId: PlacementId,
  ref: any
) {
  const { hasIntersected } = useLoadAdInView({
    ref,
    placementId,
    targetId,
    adObj
  });

  useEffect(() => {
    if (!hasIntersected) return;
    /* eslint-disable consistent-return */
    pushToAnqSafe(() => {
      window.apntag.fireImpressionTrackers(adObj);
      window.apntag.attachClickTrackers(adObj, targetId);
    });
  }, [targetId, hasIntersected]);
}

type Props = {
  targetId: string;
  placementId: PlacementId;
  adObj: AdObj;
  ref: any;
};

export function useLoadAdInView(props: Props) {
  const { ref, placementId, targetId, adObj } = props;
  const { threshold = 0, rootMargin = '0px' } =
    getInViewSettingsByBrand(placementId, adObj) || {};

  const { hasIntersected } = useInScreen({ ref, threshold, rootMargin });

  useEffect(() => {
    if (hasIntersected) {
      showTag(targetId);
    }
  }, [hasIntersected]);
  return { hasIntersected };
}
