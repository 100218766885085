import React, { useEffect, useState } from 'react';

import {
  $placementsMap,
  getPlacementList
} from '@client/core/atoms/placements';
import {
  AdServer,
  AdType,
  ClientAdPlacement
} from '@schibsted-nmp/advertising-shared';
import { PlacementItem } from '@client/dev/components/PlacementItem';

export function PlacementsTab() {
  const [placements, setPlacements] = useState<ClientAdPlacement[]>([]);
  const [filterAdServer, setFilterAdServer] = useState<AdServer | null>(null);
  const [filterAdType, setFilterAdType] = useState<AdType | null>(null);
  const [filterPlacementId, setFilterPlacementId] = useState<string>('');

  // Load filters from session storage on initial render
  useEffect(() => {
    const savedAdServer = sessionStorage.getItem(
      'ADVT_DEV_TOOL_filterAdServer'
    ) as AdServer | null;
    const savedAdType = sessionStorage.getItem(
      'ADVT_DEV_TOOL_filterAdType'
    ) as AdType | null;
    const savedPlacementId = sessionStorage.getItem(
      'ADVT_DEV_TOOL_filterPlacementId'
    );

    if (savedAdServer) setFilterAdServer(savedAdServer);
    if (savedAdType) setFilterAdType(savedAdType);
    if (savedPlacementId) setFilterPlacementId(savedPlacementId);
  }, []);

  // Save filters to session storage whenever they change
  useEffect(() => {
    sessionStorage.setItem(
      'ADVT_DEV_TOOL_filterAdServer',
      filterAdServer || ''
    );
    sessionStorage.setItem('ADVT_DEV_TOOL_filterAdType', filterAdType || '');
    sessionStorage.setItem(
      'ADVT_DEV_TOOL_filterPlacementId',
      filterPlacementId
    );
  }, [filterAdServer, filterAdType, filterPlacementId]);

  // Subscribe to placement updates
  useEffect(() => {
    $placementsMap.subscribe(() => {
      setPlacements(getPlacementList());
    });
  }, []);

  const filteredPlacements = placements
    .filter(
      (placement) =>
        !filterAdServer || placement.adServer?.type === filterAdServer
    )
    .filter((placement) => {
      if (!filterAdType) return true;
      return placement.creativeType === filterAdType;
    })
    .filter((placement) =>
      filterPlacementId
        ? placement.placementId
            .toLowerCase()
            .includes(filterPlacementId.toLowerCase())
        : true
    );

  const adServers = [AdServer.GAM, AdServer.Xandr, AdServer.AFS, AdServer.Adn];
  const adTypes: AdType[] = ['banner', 'native'];

  return (
    <div className="ml-8 mr-8 flex flex-col gap-4">
      <div className="flex gap-4 mb-16">
        {/* Filter by Placement ID */}
        <div className="flex-grow">
          <label className="text-xs" htmlFor="placementIdFilter">
            Placement ID:
          </label>
          <input
            id="placementIdFilter"
            type="text"
            placeholder="Search"
            value={filterPlacementId}
            onChange={(e) => setFilterPlacementId(e.target.value)}
            className="border p-2 rounded"
          />
        </div>
        {/* Filter by Ad Server */}
        <div>
          <label className="text-xs" htmlFor="adServerFilter">
            Ad Server:
          </label>
          <select
            id="adServerFilter"
            value={filterAdServer || ''}
            onChange={(e) =>
              setFilterAdServer(
                e.target.value ? (e.target.value as AdServer) : null
              )
            }
            className="border p-2 rounded w-full"
          >
            <option value="">All</option>
            {adServers.map((type) => (
              <option key={type} value={type}>
                {type} (
                {placements.filter((p) => p.adServer.type === type).length})
              </option>
            ))}
          </select>
        </div>
        {/* Filter by Creative Type */}
        <div className="">
          <label className="text-xs" htmlFor="creativeTypeFilter">
            Creative Type:
          </label>
          <select
            id="creativeTypeFilter"
            value={filterAdType || ''}
            onChange={(e) =>
              setFilterAdType(
                e.target.value ? (e.target.value as AdType) : null
              )
            }
            className="border p-2 rounded w-full"
          >
            <option value="">All</option>
            {adTypes.map((type) => (
              <option key={type} value={type}>
                {type} (
                {placements.filter((p) => p.creativeType === type).length})
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Filtered Placements List */}
      <div>
        <strong className="m-8">
          Placements: ({filteredPlacements.length})
        </strong>
        <div className="overflow-auto">
          <ul className="flex flex-col gap-4">
            {filteredPlacements
              .sort((a, b) => a.placementId.localeCompare(b.placementId))
              .map((placement) => (
                <PlacementItem
                  key={placement.placementId}
                  placement={placement}
                />
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
