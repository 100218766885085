import { useEffect, useState } from 'react';

const SMALLSCREEN_WIDTH = 700;

export const useIsSmallScreen = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return undefined;
    }

    const handleResize = () =>
      setIsSmallScreen(window.innerWidth < SMALLSCREEN_WIDTH);

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isSmallScreen;
};
