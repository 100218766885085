import React, { useEffect, useRef } from 'react';

import { NativeAdObj } from '@schibsted-nmp/advertising-shared';

type Props = {
  adObj: NativeAdObj;
  extraInset?: string;
  placement?: 'right' | 'left';
  className?: string;
};

const NativeCogwheel = ({
  adObj,
  className = '',
  placement = 'right',
  extraInset = '0'
}: Props) => {
  const element = useRef<HTMLDivElement>(null);

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const cornerStyle = 'absolute s-bg opacity-75 z-1';

  const spacingRightStyle = `top-${extraInset} right-${extraInset}`;
  const spacingLeftStyle = `top-${extraInset} left-${extraInset}`;

  const placementStyle =
    placement === 'right'
      ? `${spacingRightStyle} rounded-bl-8`
      : `${spacingLeftStyle} rounded-br-8`;

  useEffect(() => {
    const currentElement = element.current;
    if (currentElement) {
      currentElement.addEventListener('click', handleClick);
      currentElement.addEventListener('mousedown', handleClick);
    }

    return () => {
      if (currentElement) {
        currentElement.removeEventListener('click', handleClick);
        currentElement.removeEventListener('mousedown', handleClick);
      }
    };
  }, []);

  return (
    <div
      id={`${adObj.targetId}-button`}
      className={`${className} ${cornerStyle} ${placementStyle} cursor-pointer`}
      style={{
        height: 26,
        width: 26
      }}
      ref={element}
    />
  );
};

export default NativeCogwheel;
