import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import { $lifecycleEvents } from '@client/core/atoms/metrics';

export const SignificantEventsTab = () => {
  const [significantEvents, setSignificantEvents] = useState<string[]>([]);

  useEffect(() => {
    $lifecycleEvents.subscribe(() => {
      setSignificantEvents($lifecycleEvents.get());
    });
  }, []);

  return (
    <div className="list-decimal gap-4 flex flex-col">
      {significantEvents.map((event, i) => (
        <div
          className={classNames('wrap  pl-8 pr-8', {
            's-bg-subtle': i % 2 !== 0
          })}
          key={event}
        >
          {event}
        </div>
      ))}
    </div>
  );
};
