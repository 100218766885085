import React, { useState, useEffect } from 'react';

import { TogglePanelButton } from '@client/dev/components/TogglePanelButton';
import { DevPanelContainer } from '@client/dev/components/DevPanelContainer';
import { Tabs } from '@client/dev/components/Tabs';
import { ShortStatus } from '@client/dev/components/ShortStatus';
import { PlacementsTab } from '@client/dev/components/Tabs/PlacementsTab';
import { FeatureFlagTab } from '@client/dev/components/Tabs/FeatureFlagTab';
import { SignificantEventsTab } from '@client/dev/components/Tabs/SignificantEventsTab';
import ErrorTab from '@client/dev/components/Tabs/ErrorTab';
import DataTab from '@client/dev/components/Tabs/DataTab';
import { ToolsTab } from '@client/dev/components/Tabs/ToolsTab';
import RelevantTab from '@client/dev/components/Tabs/RelevantTab';

const tabs = [
  'placements',
  'flags',
  'data',
  'relevant',
  'errors',
  'significant events',
  'tools'
];

type TabType = (typeof tabs)[number];

const DevPanel = () => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [selectedTab, setSelectedTab] = useState<TabType>('placements');

  useEffect(() => {
    const savedTab = sessionStorage.getItem(
      'ADVT_DEV_TOOL_selectedTab'
    ) as TabType | null;
    if (savedTab && tabs.includes(savedTab)) {
      setSelectedTab(savedTab);
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem('ADVT_DEV_TOOL_selectedTab', selectedTab);
  }, [selectedTab]);

  const renderTabContent = () => {
    switch (selectedTab) {
      case 'placements':
        return <PlacementsTab />;
      case 'flags':
        return <FeatureFlagTab />;
      case 'significant events':
        return <SignificantEventsTab />;
      case 'errors':
        return <ErrorTab />;
      case 'data':
        return <DataTab />;
      case 'relevant':
        return <RelevantTab />;
      case 'tools':
        return <ToolsTab />;
      default:
        return null;
    }
  };

  return (
    <DevPanelContainer isOpen={isOpen}>
      <TogglePanelButton isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className="s-bg-selected pt-24">
        <ShortStatus />
        <Tabs
          tabs={tabs}
          selectedTab={selectedTab}
          onTabClick={(newTab) => setSelectedTab(newTab as TabType)}
        />
      </div>
      {renderTabContent()}
    </DevPanelContainer>
  );
};

export default DevPanel;
