import React, { ReactNode, useEffect, useState } from 'react';

import classNames from 'classnames';

import { $placementMetricSequence } from '@client/core/atoms/metrics';
import { $statusSequence } from '@client/core/atoms/placements';
import { listenKeys } from '@podium/store';
import {
  ClientAdPlacement,
  getSizesByMediaType,
  PlacementId
} from '@schibsted-nmp/advertising-shared';

export function PlacementItem({ placement }: { placement: ClientAdPlacement }) {
  const { placementId } = placement;
  const [adStatusSequence, setAdStatusSequence] = useState<string[]>([]);
  const [metricStatusSequence, setMetricStatusSequence] = useState<string[]>(
    []
  );

  useEffect(() => {
    const unsubscribe = listenKeys(
      $statusSequence,
      [`${placementId}`],
      (value) => {
        const placementSequence = value[placementId];
        if (Array.isArray(placementSequence)) {
          setAdStatusSequence(placementSequence);
        }
      }
    );
    return unsubscribe;
  }, [placementId]);

  useEffect(() => {
    const unsubscribe = $placementMetricSequence.subscribe(() => {
      setMetricStatusSequence(
        $placementMetricSequence.get()[placementId] || []
      );
    });
    return () => unsubscribe();
  }, [placementId]);

  const renderInfo = ({
    label,
    shouldHide,
    value
  }: {
    label: string;
    shouldHide?: boolean;
    value: string | ReactNode;
  }) =>
    shouldHide ? null : (
      <div className="flex flex-col text-s gap-0 text-align-center justify-self-start">
        <small>{label}:</small>
        <b>{value}</b>
      </div>
    );

  const isNoFill = metricStatusSequence.includes('ad_no_fill');

  const placementSizes =
    placement.adServer.type === 'gam'
      ? getSizesByMediaType(placement.adServer.config?.mediaTypes || [])
      : [];

  return (
    <li
      onClick={() => showPlacement(placementId)}
      className={classNames('cursor-pointer border p-4 rounded-4', {
        's-border-positive s-bg-positive-subtle':
          placement?.status === 'loaded',
        's-border-negative s-bg-negative-subtle':
          placement?.status === 'error' && !isNoFill,
        's-border s-bg-subtle': isNoFill
      })}
    >
      <h4>{placementId}</h4>

      {placement && (
        <div className="grid grid-cols-3 text-caption">
          {[
            {
              label: 'Status',
              value: isNoFill ? (
                'No fill'
              ) : (
                <div
                  className={classNames('', {
                    's-text-negative': placement.status === 'error',
                    's-text-positive': placement.status === 'loaded'
                  })}
                >
                  {placement.status} {isNoFill && '(No fill)'}
                </div>
              )
            },
            {
              label: 'Ad Server',
              value: placement.adServer.type
            },
            {
              label: 'AdType',
              value: placement.creativeType || 'not set'
            },
            {
              label: 'Intermingle',
              shouldHide:
                !placement?.intermingle?.grid && !placement?.intermingle?.list,
              value: `Grid: ${placement.intermingle?.grid} | List: ${placement.intermingle?.list}`
            },
            {
              label: 'Sizes',
              value: placementSizes.length
                ? placementSizes
                    .map((size) =>
                      Array.isArray(size) ? size.join('x') : size
                    )
                    .join(', ')
                : 'not set'
            }
          ].map((info) => renderInfo(info))}
        </div>
      )}

      <div className="text-s mt-8">Metrics sequence:</div>
      <div className="flex flex-row flex-wrap gap-4">
        {metricStatusSequence?.length ? (
          metricStatusSequence.map((status, index) => (
            <StatusItem
              key={index + status}
              status={status}
              index={index}
              hasBackground={status === 'refresh'}
            />
          ))
        ) : (
          <div className="text-s">None</div>
        )}
      </div>

      <div className="text-s mt-8">Ad Status sequence:</div>
      <div className="flex flex-row flex-wrap gap-4">
        {adStatusSequence?.length ? (
          adStatusSequence.map((status, index) => (
            <StatusItem
              key={index + status}
              status={status}
              index={index}
              hasBackground={status === 'pending'}
            />
          ))
        ) : (
          <div className="text-s">None</div>
        )}
      </div>
    </li>
  );
}

const showPlacement = (placementId: PlacementId) => {
  const el = document.getElementById(placementId);
  if (el) {
    console.log(`Found ${placementId}. Scrolling to it!`);
    el.scrollIntoView({ behavior: 'smooth' });

    el.style.border = '4px solid blue';
    el.style.transition = 'none';

    setTimeout(() => {
      el.style.transition = 'border 0.3s ease';
      el.style.border = '0px solid blue';
    }, 2000);
  }
};

interface StatusItemProps {
  status: string;
  index: number;
  hasBackground: boolean;
}
const StatusItem = ({ status, hasBackground }: StatusItemProps) => (
  <div
    title={hasBackground ? 'Filter was updated here' : status}
    className={classNames('p-2 text-s rounded-4 border', {
      's-bg-warning-subtle': hasBackground,
      's-bg-negative-subtle': status === 'error'
    })}
    key={status}
  >
    {status === 'error' ? 'noFill' : status}
  </div>
);
