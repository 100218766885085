import classNames from 'classnames';
import { css } from 'lit';

import { AdStatus, AdType } from '@schibsted-nmp/advertising-shared';

export function getClassNameByStatus(status: AdStatus) {
  return classNames({
    'advt-loaded': status === 'available' || status === 'loaded'
  });
}

export function getClassNameByType(type: AdType) {
  return classNames({
    'native-ad': type === 'native',
    'video-ad': type === 'video',
    'banner-ad': type === 'banner' || type === 'adnami',
    'sponsored-content': type === 'sponsored-content',
    'takeover-ad': type === 'takeover'
  });
}

export default css`
  /* ================================================
  General styles and ad-vendor specific styles
  =================================================== */

  :host {
    position: relative;
    grid-column: 1 / -1;
  }

  :host(.advt-loaded) {
    display: block;
  }

  :host(.advt-x) {
    display: block;
    z-index: 2;
  }

  :host(.advt-g) {
    margin: 0 auto;
    grid-column: 1 / -1;
    width: 100%;
  }

  :host(.native-ad) {
    height: 100%;
  }

  :host(.video-ad) {
    margin: 0 auto;
  }

  :host(.sponsored-content) {
    grid-column: 1 / -1;
  }

  :host(.advt-empty) {
    display: none;
  }

  /* ================================================
  Placement specific styles where page-type style and placementId styles are used 
  =================================================== */

  :host(.banner-ad.object-page[placementid*='advt_recirc_middle_2']),
  :host(.banner-ad.result-page[placementid*='advt_middle']) {
    margin: 0 auto;
    overflow: hidden;
    grid-column: 1 / -1;
  }

  :host(.native-ad.result-page[placementid*='advt_middle']) {
    grid-column: unset;
    margin: unset;
    overflow: visible;
  }

  :host(.takeover-ad[placementid*='middle_']) {
    margin: 0 auto;
    grid-column: 1 / -1;
    width: 100%;
    overflow: visible;
  }

  :host([placementid='advt_top_2']) {
    width: 100%;
    max-width: 100%;
    min-height: 50px;
    margin: 12px 0;
    overflow: hidden;
  }

  /* Handle 5 rows banner ad in recirc placement 1 */
  :host(.banner-ad[placementId='advt_recirc_middle_1']) {
    grid-column: 1 / 3;
  }

  :host([placementid='advt_bottom_1']) {
    overflow: visible;
    margin: 20px 0;
  }

  :host([placementid='advt_bottom_1']) #advt_bottom_1 {
    margin-left: auto;
  }

  :host([placementid*='advt_recirc_middle_']) #advt_bottom_1 {
    width: 100%;
  }

  :host(.video-ad[placementid*='middle_']) iframe {
    margin-left: auto;
    margin-right: auto;
  }

  :host(
      .advt-g.advt-loaded:is(
          .mobility-vertical,
          .boat-vertical,
          .mc-vertical
        ):is(.native-ad, .sponsored-content)[placementid*='advt_middle_']
    ) {
    padding: 12px;
  }

  @media (max-width: 989px) {
    :host(.banner-ad[placementid*='advt_recirc_middle_1']) {
      margin: 0 auto;
      overflow: hidden;
      grid-column: 1 / -1;
    }
  }

  :host(.banner-ad[placementid*='advt_wallpaper']) {
    max-width: 100vw;
    overflow: hidden;
  }

  :host(.banner-ad[placementid*='right']),
  :host(.banner-ad[placementid*='left']) {
    position: sticky;
    transform: translateZ(0);
    z-index: 2;
    top: 66px;
  }

  /* Hide Recommendation ads until they are initialized by the podlet */
  :host(:not(.advt-loaded)):host([placementid*='ad-stream__native-ad']),
  :host(:not(.advt-loaded)):host([placementid*='advt_recirc_middle']) {
    height: 0;
    width: 0;
    margin: 0;
    padding: 0;
    grid-column: 1 / -1;
    visibility: hidden;
  }

  /* Don't overflow for mobile devices on middle placements, it breaks the UI */
  @media (max-width: 767px) {
    :host(.banner-ad[placementid*='bottom_1']),
    :host(.banner-ad[placementid*='middle_']),
    :host(.banner-ad[placementid*='advt_top_2']) {
      overflow: hidden;
      max-width: 100%;
    }

    /* Added specificity to prevent generic overflow */
    :host(.banner-ad.advt-loaded),
    :host(.native-ad.advt-loaded),
    :host(.video-ad.advt-loaded) {
      overflow: hidden;
    }
  }
`;
