import React, { Dispatch, FC, SetStateAction } from 'react';

import classNames from 'classnames';

export const TogglePanelButton: FC<{
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}> = ({ isOpen, setIsOpen }) => {
  const onToggleClick = (e: any) => {
    e.preventDefault();
    setIsOpen((prevState) => !prevState);
  };
  const onCloseClick = (e: any) => {
    e.preventDefault();
    (window.ADVT as any).closeDevTool();
  };

  return (
    <div
      className={classNames('absolute top-0 left-0 z-50 flex align-start', {
        'p-4 gap-4': isOpen,
        'p-0 gap-0': !isOpen
      })}
    >
      <button
        className={classNames(
          'font-bold rounded-full text-s h-20  overflow-hidden whitespace-nowrap',
          {
            'w-20': isOpen,
            'w-0': !isOpen
          }
        )}
        style={{
          backgroundColor: '#ff3d3d',
          color: '#bf0000',
          transition:
            'width 500ms ease, background-color 500ms ease, font-size-color 500ms ease'
        }}
        onClick={onCloseClick}
      >
        X
      </button>
      <button
        className={classNames('font-bold overflow-hidden whitespace-nowrap', {
          'w-20 text-m rounded-full h-20': isOpen,
          's-bg-primary w-96 text-s rounded-0 h-32 s-text-inverted': !isOpen
        })}
        style={{
          ...(isOpen && { background: '#ffbe36', color: '#e09800' }),
          transition:
            'width 500ms ease, background-color 500ms ease, font-size-color 500ms ease'
        }}
        onClick={onToggleClick}
      >
        {isOpen ? '-' : 'Ad Debug'}
      </button>
    </div>
  );
};
