import React from 'react';

import { createRoot } from 'react-dom/client';

import GamAdUnit from '@client/adManager/AdUnit/GamAdUnit';
import { $placementsMap } from '@client/core/atoms/placements';
import { isFeatureEnabled } from '@client/core/atoms/unleashFeatures';
import { RenderSponsoredContentOrChildren } from '@client/sponsoredContent/RenderSponsoredContentOrChildren';
import { shouldRenderSponsoredContent } from '@client/sponsoredContent/shouldRenderSponsoredContent';
import {
  debugLog,
  GamPlacement,
  isForecastingPlacement,
  UNLEASH_FEATURE_NAME
} from '@schibsted-nmp/advertising-shared';
import { ClientAdPlacement } from '@schibsted-nmp/advertising-shared/src/types/client';

import { AdSwitchComponent, AdSwitchProps } from './adSwitch/AdSwitchComponent';

export function renderAd(props: AdSwitchProps) {
  const containerElement = document.getElementById(props.containerId);
  if (containerElement) {
    if (containerElement.querySelector(`#${props.placementId}`)) {
      debugLog(`Ad already rendered for ${props.placementId}`);
      return;
    }

    const placementElement = createRoot(containerElement);
    if (placementElement) {
      const forecastingEnabled = isFeatureEnabled(
        UNLEASH_FEATURE_NAME.enableGamForecasting
      );

      const placements = $placementsMap.get();
      if (Object.keys(placements).length === 0) {
        console.error(`No placements found`);
        return;
      }
      const placement = placements[
        `forecasting-${props.placementId}`
      ] as ClientAdPlacement<GamPlacement>;
      const Children = (
        <>
          {forecastingEnabled &&
            placement &&
            isForecastingPlacement(placement) && (
              <GamAdUnit
                placement={placement}
                containerId={props.containerId}
              />
            )}
          <AdSwitchComponent {...props} />
        </>
      );

      if (shouldRenderSponsoredContent(props.placementId)) {
        placementElement.render(
          <RenderSponsoredContentOrChildren {...props}>
            {Children}
          </RenderSponsoredContentOrChildren>
        );
      } else {
        placementElement.render(Children);
      }
    }
  } else {
    debugLog(`Container element with id ${props.containerId} not found`);
  }
}
