import React from 'react';

import classNames from 'classnames';

interface TabComponentProps {
  tabs: string[];
  selectedTab: string;
  onTabClick: (newTab: string) => void;
}

export const Tabs = ({ tabs, selectedTab, onTabClick }: TabComponentProps) => (
  <div className="w-full overflow-auto">
    <div className="flex gap-4 s-bg-selected pt-4 pr-2 pl-2 w-max">
      {tabs.map((tab) => (
        <button
          key={tab}
          className={classNames('p-4 w-max text-xs', {
            's-text-subtle s-bg-hover': tab !== selectedTab,
            's-text-link s-bg font-bold': tab === selectedTab
          })}
          style={{
            borderRadius: '8px 8px 0 0',
            transition: 'background-color 0.2s ease, transform 0.2s ease'
          }}
          onClick={(e: any) => {
            e.preventDefault();
            onTabClick(tab);
          }}
        >
          {tab}
        </button>
      ))}
    </div>
  </div>
);
