import React from 'react';

import { createRoot } from 'react-dom/client';

import DevPanel from '@client/dev/DevPanel';

export function renderDevPanel(divRoot: Element) {
  if (divRoot) {
    const root = createRoot(divRoot);
    root.render(<DevPanel />);
  }
}
