import React, { useMemo, useRef } from 'react';

import { BannerAdObj, PlacementId } from '@schibsted-nmp/advertising-shared';
import { useLoadAdInView } from '@client/core/hooks';

import { useRemoveUtifSizes } from './useSizeHacks';

type Props = {
  targetId: string;
  placementId: PlacementId;
  adObj: BannerAdObj;
  className: string;
};

export default function BannerAdComponent(props: Props) {
  const { placementId, targetId, adObj, className } = props;
  const ref = useRef<HTMLDivElement>(null);

  useRemoveUtifSizes(targetId);

  useLoadAdInView({ placementId, targetId, ref, adObj });

  // needed to center search result ads
  const style = useMemo(() => {
    if (placementId?.includes('board_') || placementId?.includes('middle_')) {
      return { width: adObj?.width ? `${adObj.width}px` : '' };
    }
    return {};
  }, [placementId, adObj]);

  return <div className={className} style={style} ref={ref} id={targetId} />;
}
