import React from 'react';

import { $config } from '@client/core/atoms/config';

import { ObjectExplorer } from '../ObjectExplorer';

export default function DataTab() {
  const config = $config.get();
  return (
    <div>
      <ObjectExplorer data={config} />
    </div>
  );
}
