import React, { useState } from 'react';

export const ToolsTab = () => {
  const [message, setMesseage] = useState<string>('');

  const promptUserToOpenConsole = () => {
    setMesseage('Ran script. Open the console ✓');
    setTimeout(() => {
      setMesseage('');
    }, 4000);
  };

  const onGamConsoleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    window.googletag.openConsole();
  };

  const onAnalysisClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    window.ADVT_EVENTS.analysis();
    promptUserToOpenConsole();
  };

  const onDebugLogsClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const url = new URL(window.location.href);
    setMesseage('Enabled after the reload');

    setTimeout(() => {
      url.searchParams.set('advt_debug', 'true');
      window.location.href = url.toString();
    }, 1000);
  };

  return (
    <div className="p-16">
      {message && (
        <div className="flex justify-center bg-disabled p-16 m-16 rounded">
          {message}
        </div>
      )}
      <div className="flex flex-col gap-16">
        <button
          className="bg-primary text-inverted p-16 rounded border-t-warning border-r-info border-b-danger border-l-success"
          onClick={onGamConsoleClick}
        >
          Google publisher console
        </button>
        <button
          className="bg-primary text-inverted p-16 rounded"
          onClick={onAnalysisClick}
        >
          ADVT_EVENTS.analysis
        </button>
        <button
          className="bg-primary text-inverted p-16 rounded"
          onClick={onDebugLogsClick}
        >
          ADVT Debug logs
        </button>
      </div>
      <div className="mt-16 text-sm">
        Other tools you might like would be{' '}
        <a
          href="https://chromewebstore.google.com/detail/professor-prebid/kdnllijdimhbledmfdbljampcdphcbdc?hl=en"
          className="text-link underline"
        >
          Professor Prebid
        </a>{' '}
        chrome extension
      </div>
    </div>
  );
};
