import React, { ReactNode, useEffect, useState } from 'react';

import { updatePlacementKeyValueById } from '@client/core/atoms/placements';
import { events, messageBus } from '@schibsted-nmp/advertising-events';

import { AdSwitchProps } from '../xandr/components/adSwitch/AdSwitchComponent';

type Props = AdSwitchProps & {
  children: ReactNode;
};

export function RenderSponsoredContentOrChildren(props: Props) {
  const { children: backFillAd } = props;
  const [loadBackFill, setLoadBackFill] = useState(false);

  useEffect(() => {
    updatePlacementKeyValueById(props.placementId, 'status', 'available');
    updatePlacementKeyValueById(
      props.placementId,
      'creativeType',
      'sponsored-content'
    );
  }, [props.placementId]);

  useEffect(() => {
    function handleSponsoredContentFallbackCallback() {
      setLoadBackFill(true);
    }
    // check if there already are messages received
    const peekSponsoredContentEvent = messageBus.peek(
      events.PODLET.channel,
      events.PODLET.CONTENT_MARKETING_NO_SOV.topic
    );

    if (peekSponsoredContentEvent) {
      handleSponsoredContentFallbackCallback();
    }

    const unsubscribeContentManagement = messageBus.subscribe(
      events.PODLET.channel,
      events.PODLET.CONTENT_MARKETING_NO_SOV.topic,
      handleSponsoredContentFallbackCallback
    );
    return () => {
      if (unsubscribeContentManagement) {
        unsubscribeContentManagement();
      }
    };
  }, []);

  if (loadBackFill) return backFillAd;

  return <contentful-sov-fetcher adPosition={props.placementId} />;
}
