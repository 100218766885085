import React, { useEffect, useState } from 'react';

import classNames from 'classnames';

import { useInitiateGamUnit } from '@client/adManager/AdUnit/useInitiateGamUnit';
import { $config } from '@client/core/atoms/config';
import { $placementsMap } from '@client/core/atoms/placements';
import { isFeatureEnabled } from '@client/core/atoms/unleashFeatures';
import { setAdnamiPlacement } from '@client/core/utils/dom/handleAdsOverlap/handleStickySkyscrapers';
import { observeFullWidthAd } from '@client/core/utils/dom/handleAdsOverlap/observeFullWidthAd';
import {
  AdServer,
  ClientAdPlacement,
  debugLog,
  GamPlacement,
  isForecastingPlacement,
  PlacementId,
  UNLEASH_FEATURE_NAME
} from '@schibsted-nmp/advertising-shared';

type Props = {
  placement: ClientAdPlacement<GamPlacement>;
  containerId: string;
};
function GamAdUnit({ containerId, placement }: Props) {
  const { brand, deviceType, vertical } = $config.get();
  const { placementId } = placement;
  const ref = React.createRef<HTMLDivElement>();
  const forecasting = isForecastingPlacement(placement);

  const hasTestCampaignFlag = isFeatureEnabled(
    UNLEASH_FEATURE_NAME.enableGamTestCampaign
  );

  const [clientPlacement, setClientPlacement] =
    useState<ClientAdPlacement<GamPlacement>>(placement);
  const { creativeType } = clientPlacement;

  useEffect(() => {
    const unsubscribe = $placementsMap.subscribe((placements) => {
      const clientPlacement =
        typeof placements === 'object' && placements[placement.placementId];
      if (!clientPlacement) {
        return;
      }
      if (clientPlacement.adServer.type === AdServer.GAM) {
        setClientPlacement(clientPlacement as ClientAdPlacement<GamPlacement>);
      }
    });
    return () => unsubscribe();
  }, [placement]);

  useInitiateGamUnit({
    forecasting,
    placement: clientPlacement,
    containerId,
    ref
  });

  useEffect(() => {
    if (creativeType === 'adnami') {
      setAdnamiPlacement(placementId);
    } else if (
      brand === 'tori' &&
      placementId === PlacementId.Middle2 &&
      deviceType === 'desktop'
    ) {
      observeFullWidthAd(placementId, () => {
        debugLog('Full width ad detected');
        setAdnamiPlacement(placementId);
      });
    }
  }, [creativeType, placementId]);

  if (forecasting && !hasTestCampaignFlag) {
    return (
      <div
        style={{
          opacity: 0,
          width: 1,
          height: 1,
          position: 'absolute'
        }}
        ref={ref}
        id={placementId}
      />
    );
  }

  const isNativeAdStyles =
    creativeType === 'native' || creativeType === 'sponsored-content';
  const cardNativeAdVertical = ['realestate', 'travel'].includes(vertical);
  const cardNativeAd = isNativeAdStyles && cardNativeAdVertical;
  return (
    <div ref={ref} className="flex w-full h-full">
      <div
        className={classNames('mx-auto h-full', {
          'w-full':
            creativeType === 'native' || creativeType === 'sponsored-content',
          'shadow-s hover:shadow-m border border-gray-200 rounded-8':
            cardNativeAd || creativeType === 'sponsored-content'
        })}
        id={placementId}
        data-is-adnami={creativeType === 'adnami'}
      />
    </div>
  );
}

export default GamAdUnit;
