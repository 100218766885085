import { $config } from '@client/core/atoms/config';
import {
  getPlacementList,
  listenPlacementKeysForValueChange
} from '@client/core/atoms/placements';
import {
  fetchAllElements,
  fetchElement,
  fetchElementById,
  getDocumentHeight,
  querySelect,
  styleElements
} from '@client/core/utils/dom/domUtils';
import { PlacementId } from '@schibsted-nmp/advertising-shared';

const BANNERS_STICKY_CONTAINER_CLASS_NAME_QUERY = '.banners__sticky-container';
const FOOTER_ELEM_QUERY = 'finn-footer';
const offset = 100;
const FOOTER_HEIGHT_PX = 500;
let bannerStickyContainers: NodeListOf<HTMLElement> | null = null;
const activeAdnamiPlacementIds: string[] = [];

const fetchStickyContainer = () => {
  if (!bannerStickyContainers) {
    bannerStickyContainers = fetchAllElements(
      BANNERS_STICKY_CONTAINER_CLASS_NAME_QUERY
    );
  }
};

const setSkyscrapersStickyHeight = () => {
  const hasTwoSkyscraperRows = $config
    .get()
    .placements.some(
      (placement) =>
        placement.placementId === PlacementId.Right2 ||
        placement.placementId === PlacementId.Left2
    );

  const firstRowStickyContainers = fetchAllElements('.banners__sticky--1');
  const secondRowStickyContainers = fetchAllElements('.banners__sticky--2');
  const { brand } = $config.get();

  const isDbaMastercampanionAd =
    brand === 'dba' && fetchElement('#advt_wallpaper iframe');
  if (isDbaMastercampanionAd) {
    const skyscraperAds = fetchAllElements(
      '.banners__sticky--1 advt-component'
    );
    styleElements(skyscraperAds, { marginTop: '180px' });
    styleElements(firstRowStickyContainers, { height: 'calc(1080px - 180px)' });
  } else if (hasTwoSkyscraperRows) {
    styleElements(firstRowStickyContainers, { height: '1080px' });
  } else {
    styleElements(firstRowStickyContainers, { height: '100%' });
  }

  if (hasTwoSkyscraperRows) {
    styleElements(secondRowStickyContainers, {
      height: 'calc(100% - 1080px)',
      marginTop: '1080px'
    });
  } else {
    styleElements(secondRowStickyContainers, { height: '0px' });
  }
};

const setSkyscraperContainerHeight = () => {
  setSkyscrapersStickyHeight();
  const collidingElements: HTMLElement[] = activeAdnamiPlacementIds
    .map((placementId) => fetchElementById(placementId))
    .filter((placement) => placement !== null);

  const footer = querySelect(FOOTER_ELEM_QUERY);
  if (footer) collidingElements.push(footer);

  const firstCollisionElement =
    collidingElements.sort(
      (a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top
    )[0] || null;

  if (firstCollisionElement) {
    setBannerStickyContainerHeight(
      firstCollisionElement.getBoundingClientRect().top +
        window.scrollY -
        offset
    );
  } else {
    // fallback. eg. if footer element was not found
    setBannerStickyContainerHeight(
      getDocumentHeight() - FOOTER_HEIGHT_PX - offset
    );
  }
};

const setBannerStickyContainerHeight = (newHeight: number) => {
  fetchStickyContainer();
  if (!bannerStickyContainers) return;
  bannerStickyContainers.forEach((container) => {
    container.style.height = `${newHeight}px`;
  });
};

export function setAdnamiPlacement(placementId: PlacementId) {
  if (
    placementId &&
    !activeAdnamiPlacementIds.some((placements) => placements === placementId)
  ) {
    activeAdnamiPlacementIds.push(placementId);
    setSkyscraperContainerHeight();
  }
}

export const initiateHandleStickySkyscrapers = () => {
  getPlacementList().forEach((placement) => {
    listenPlacementKeysForValueChange(
      placement.placementId,
      'status',
      (status, oldStatus) => {
        if (status === 'loaded' && oldStatus !== 'loaded') {
          setSkyscraperContainerHeight();
        }
      }
    );
  });
};
